import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder,FormControl, FormGroup,FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-create-staff',
  templateUrl: './create-staff.component.html',
  styleUrls: ['./create-staff.component.css']
})
export class CreateStaffComponent implements OnInit {

  public disable=false;
  public submitted = false;
  public flag = false;
  public staffData:any[]=[];
  public rowDataStaff:any[]=[];
  public StaffModel: FormGroup;
  public user: any;
  public id:string="";
  public dob: string = "";
  public firstName:string;
  public lastName:string;
  public nameseriesdata:any=[];
  public nameserieseditdata:any=[];
  public namearray:any=[];
  public selectall:any;
  // this.nameseriesdata = [
  //   { name: 'A', value: 'A' },{ name: 'B', value: 'B' },{ name: 'C', value: 'C' },   
  //   { name: 'D', value: 'D' },{ name: 'E', value: 'E' },{ name: 'F', value: 'F' }, 
  //   { name: 'G', value: 'G' },{ name: 'H', value: 'H' },{ name: 'I', value: 'I' },   
  //   { name: 'J', value: 'J' },{ name: 'K', value: 'K' },{ name: 'L', value: 'L' },  
  //   { name: 'M', value: 'M' },{ name: 'N', value: 'N' },{ name: 'O', value: 'O' },   
  //   { name: 'P', value: 'P' },{ name: 'Q', value: 'Q' },{ name: 'R', value: 'R' }, 
  //   { name: 'S', value: 'S' },{ name: 'T', value: 'T' },{ name: 'U', value: 'U' },   
  //   { name: 'V', value: 'V' },{ name: 'W', value: 'W' },{ name: 'X', value: 'X' },
  //   { name: 'Y', value: 'Y' },{ name: 'Z', value: 'Z' }     
  // ];

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { 
      this.titleservice.setTitle("Create Staff");
      this.StaffModel = fb.group({  
        RequestType:'ADD',
        id:'',
        password:['',Validators.required],
        firstName:['',Validators.required],
        lastName:'',//['',Validators.required],
        contactno:'',//['',Validators.required],
        email:['',Validators.required,Validators.email],
        nameSeries:new FormArray([]),
        employeeId:'',
        department:'',//['',Validators.required],
        school:'',//['',Validators.required],
      });
    }

    
    
    // onCheckboxChange(e) {
    //   const checkArray: FormArray = this.StaffModel.get('nameSeries') as FormArray;
     
    //   if (e.target.checked) {
    //     checkArray.push(new FormControl(e.target.value));
        
    //   } else {
    //     let i: number = 0;
    //     checkArray.controls.forEach((item: FormControl) => {
    //       if (item.value == e.target.value) {
    //         checkArray.removeAt(i);
    //         return;
    //       }
    //       i++;
    //     });
    //   }

    // }

    onCheckboxChange(e) {

        if(e.target.checked){
        this.selectall=null;
          this.selectall={
            checked:false
          }
            this.namearray.push(e.target.value);
        }else{
          let i: number = 0;
            this.namearray.forEach(item => {
              if (item.value == e.target.value) {
                this.namearray.removeAt(i);
                return;
              }
              i++;
            });
        }

    }

    onchangeselectAll(e){

      if(e.target.checked){
        this.selectall={
          checked:true
        }
        this.namearray=[];
        this.nameseriesdata=[];
        for (let i = 65; i <= 90;i++) {
          let dt={ name: String.fromCharCode(i), value: String.fromCharCode(i),checked:false }
          this.nameseriesdata.push(dt);
        } 
      }

    }

  ngOnInit(): void {


    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
      if(user.role!="admin"){
        this.routing.navigate(['admin']);
      }
    }else{
      this.routing.navigate(['admin']);
    }

    this.id="";
    this.user = this.loginService.getUser();
    this.id = this.route.snapshot.queryParams["id"];

    this.selectall ={
       checked:false
    }

    if(this.id!=null && this.id!=""){
      this.staffService.getStaffById(this.id).subscribe(res=>{
        if(res.status==1){
            this.EditFill(res);
            for (let i = 65; i <= 90;i++) {
              let data = this.checkAlphabet(String.fromCharCode(i),res.data[0].nameSeries);
              if(data==undefined){
                data={ name: String.fromCharCode(i), value: String.fromCharCode(i),checked:false }
              } 
              this.nameseriesdata.push(data);                         
            }
        }
      });
     }else{
        for (let i = 65; i <= 90;i++) {
          let dt={ name: String.fromCharCode(i), value: String.fromCharCode(i),checked:false }
          this.nameseriesdata.push(dt);
        }        
     }

  }

  //staff Edit

  EditFill(rowData) {
    debugger
    this.StaffModel = this.fb.group({ 
      RequestType:"UPDATE",
      id: [this.id],
      password: [rowData.data[0].password],
      firstName: [rowData.data[0].firstName],
      lastName: [rowData.data[0].lastName],
      contactno: [rowData.data[0].contactno],
      email: [rowData.data[0].emailAddress],
      nameSeries: new FormArray([]),
      employeeId: [rowData.data[0].employeeId],
      department: [rowData.data[0].department],
      school: [rowData.data[0].school]
    });
    if(this.StaffModel.value.employeeId!=null)
    {
      $("#employeeId").prop("disabled",true);
    }
    //this.disable=false;
  }


  //staff creation
  ValidateForm(): boolean {
    return true;
  }

  get f() { return this.StaffModel.controls; }
  saveStaff() {
    this.validEmail(this.StaffModel.value.email);
    this.submitted=true;
    if(this.StaffModel.invalid){
      return;
    }    
    if(this.StaffModel.value.RequestType=="ADD"){
      let obj = {
        firstName: this.StaffModel.value.firstName,
        lastName: this.StaffModel.value.lastName,
        contactno: this.StaffModel.value.contactno,
        emailAddress: this.StaffModel.value.email,
        nameSeries: this.selectall.checked==true?"A-Z" : this.namearray.join(','),
        employeeId: this.StaffModel.value.employeeId,
        password: this.StaffModel.value.password,
        department: 'StudentAid',
        school: 'ALASU',
      }
        this.staffService.CreateSatff(obj).subscribe(res=>{
          
          if(res.status==1){
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Staff created successfully'
            });
            this.onCancel();
            window.location.href="manage-staff";
          }else {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Error creating Staff'
            });
          }

        });
      } else if(this.StaffModel.value.RequestType=="UPDATE"){
        
        let obj = {
          id: this.id,
          firstName: this.StaffModel.value.firstName,
          lastName: this.StaffModel.value.lastName,
          contactno: this.StaffModel.value.contactno,
          emailAddress: this.StaffModel.value.email,
          nameSeries: this.selectall.checked==true?"A-Z" : this.namearray.join(','),
          employeeId: this.StaffModel.value.employeeId,
          password: this.StaffModel.value.password,
          department: 'StudentAid',
          school: 'ALASU',
        }

        this.staffService.UpdateSatff(obj).subscribe(res=>{

          if(res.status==1){
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Staff Updated successfully'
            });
            this.onCancel();
            window.location.href="manage-staff";
          }else {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Error Updating Staff'
            });
          }

        });

      }
    
  }

  //cancel

  onCancel() {
    this.StaffModel = this.fb.group({
       RequestType:'ADD',
       id:'',
       firstName:['',Validators.required],
       lastName:'',//['',Validators.required],
       contactno:'',//['',Validators.required],
       emailAddress:['',Validators.required,Validators.email],
       nameSeries:'',//['',Validators.required],
       employeeId:'',//['',Validators.required],
       department:'',//['',Validators.required],
       school:'',//['',Validators.required],
    });
    this.disable=false;
 }


  checkAlphabet(alphabet,data){
    // console.log(alphabet);
    // console.log(data);
     let dt = data.split(',');
    // console.log(dt);
    let obj:any;
      for(let i=0;i<dt.length;i++){
        if(dt[i] == alphabet){
          //this.StaffModel.value.nameSeries.push(alphabet);
          this.namearray.push(alphabet);
          obj = {
            name: alphabet,
              value: alphabet,
              checked:true 
          }
        }
      }

      return obj;
  }

  //validation function to validate mobile number
  validateMobile(event:any){
    const pattern = /[0-9\+\-\+\{}\+\()\ ]/;
    
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }

  }

  //validate email
  validateChangeEmail(event){
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!regex.test(event.target.value)){
      this.StaffModel.controls['email'].setValue("");
      Swal.fire("Warning!","Please enter the valid email address.","warning");
      return false;
    }
  }

  //validate email
  validEmail(email){
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!regex.test(email)){
      Swal.fire("Warning!","Please enter the valid email address.","warning");
      return false;
    }
  }

}
