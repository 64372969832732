<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>    
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme" *ngIf="link">        
        <app-staff-sidebar></app-staff-sidebar>        
    </div>
    <div class="sidebar-wrapper sidebar-theme"  *ngIf="!link">        
      <app-admin-sidebar></app-admin-sidebar>      
  </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row">
            <div class="col-md-12">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item ml-auto" *ngIf="link"><a href="dashboard/staff">Dashboard</a></li>
                <li class="breadcrumb-item  ml-auto" *ngIf="!link"><a href="dashboard/admin">Dashboard</a></li>
                <li class="breadcrumb-item active text-primary" aria-current="page"><span>Upload Students</span></li>
            </ol></div>
          </div>
       
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Upload Students</b></span>
                            </div>
                            <div class="card-body">
                                <div class="col-md-12">
                                    <form [formGroup]="studentForm">
                                        <div class="form-group">
                                            <label for="student">Copy and Paste text below</label>
                                            <textarea rows="25" cols="80" class="form-control" id="student" formControlName="student" ></textarea>
                                        </div>
                                        <div class="form-group">
                                          <button class="btn btn-primary" (click)="save()">Upload Students</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>  
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>
</div>

<!-- END MAIN CONTAINER -->