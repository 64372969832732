import { Component, OnInit } from '@angular/core';
import { StaffService } from '../../shared/staff/staff.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/login/login.service';
import {Title} from "@angular/platform-browser";

import { HttpClient } from '@angular/common/http';
declare var $;
//import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-admin-studentlist',
  templateUrl: './admin-studentlist.component.html',
  styleUrls: ['./admin-studentlist.component.css']
})
export class AdminStudentlistComponent implements OnInit {
  
  public disable=false;
  public submitted = false;
  public flag = false;
  public studentData:any[]=[];
  public rowDataStudent:any[]=[];
  public user: any;
  public dob: string = "";
  public disableExportBtn=true;
  public terms:string;
  public  text:any;
//sorting
  // key: string = 'StudentID'; //set default
  // reverse: boolean = false;
  //  sort(key){
  //   this.key = key;
  //   this.reverse = !this.reverse;
  //  }
  //  //initializing p to one
  // p: number = 1;
  constructor(private titleservice:Title,
              private _httpClient: HttpClient,
              private staffservice:StaffService,
              private loginservice:LoginService,
              private route:Router) {
    this.titleservice.setTitle("Student List");
    this.getStudentList();
   setTimeout(function(){
    $(function(){      
      $("#tblStudentList").DataTable({   
        dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
        
        buttons: {          
            buttons: [              
                { extend: 'csv', className: 'btn' },
                { extend: 'excel', className: 'btn' },
                { extend: 'print', className: 'btn' }
            ]
        },
       
        "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "order": [[ 3, "desc" ]],
      "stripeClasses": [],
      "lengthMenu": [5, 10, 20, 50],
      "pageLength": 5,
      drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
      });
   });
   },3000);
   
   }
  
  //title = 'StudentList';
  //dtOptions: DataTables.Settings = {};
   dtOptions: DataTables.Settings = {};
   
  ngOnInit(): void {
    let user=this.loginservice.getUser();

    if(user!=null||user!=undefined){
      if(user.role!="admin"){
        this.route.navigate(['admin']);
      }
    }else{
      this.route.navigate(['admin']);
    }
   
   
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 10,
    //   processing: true
    // }; 

  }

  getStudentList() 
  {
       this.staffservice.getStudentList().subscribe
       (
          student=>
          {
                  console.log(student);
                  this.studentData=student.data;
                  this.rowDataStudent=[];
                  if(this.studentData.length>0)
                  {
                    this.disableExportBtn=false;
                    for(let i=0;i<this.studentData.length;i++)
                    {
                       let obj={
                           id:this.studentData[i].id,
                           StudentID: this.studentData[i].studentID,
                           StudentName: this.studentData[i].firstName+' '+ this.studentData[i].lastName,
                           DOB: this.studentData[i].dob,       
                           EmailAddress: this.studentData[i].schoolEmailAddress,       
                       }
                       this.rowDataStudent.push(obj);
                    }
                  }
                  this.flag=true;

          });
            
  }
  Delete(id) {
   
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        
            this.staffservice.DeleteStudents(id).subscribe(res=>{
              
              if(res.status==1){
                this.getStudentList();
                Swal.fire({
                 icon: 'success',
                  title: 'Success',
                  text: 'Staff deleted successfully'
                });
              }else{
                Swal.fire({
                  icon: 'warning',
                  title: 'Warning',
                  text: 'Error in deleting staff'
                });
              }
            });   
      }
      
    });
  }

   onDown(type: string, fromRemote: boolean){
     
   }
}