export class StudentMessages {

    //Student Form Info Messages
    public static FORM_INFO_PARENTSUBMITTED="Form already submitted";

    //Student Form Swal Messages
    public static FORM_SWAL_FILEFORMATS="Please select jpg, jpeg, png, bmp, pdf files only.";
    public static FORM_SUBMITTED_SUCCESS="Form submitted successfully";
    public static FORM_SUBMITTED_ERROR="Error form not submitted";
    public static FORM_ALLREQUIRED_FIELDS="Please enter all required fields.";
    public static FORM_DOCUMENT_ACCEPT_ERROR="Error in accept document.";
    public static FORM_ENTER_REASON_SUBMITTED = "Enter the reason.";
    public static FORM_ENTER_REASON_NOTSUBMITTED = "Enter the reason.";

    //File Formats
    public static FILE_FORMATS="jpg,jpeg,png,bmp,pdf";
}
