<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div *ngIf="from=='student'" class="sidebar-wrapper sidebar-theme">
        <app-student-sidebar></app-student-sidebar>
    </div>

    <div *ngIf="from!='student'" class="sidebar-wrapper sidebar-theme">
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">    
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">        
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol *ngIf="from!='student'" class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" aria-current="page"><span>Edit Student</span></li>
                    </ol>
                    <ol *ngIf="from=='student'" class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="dashboard/student">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" aria-current="page"><span>Update Profile</span></li>
                    </ol>
                </div>
            </div>
            <div class="card">
                <div class="card-header bg-secondary"><b class="text-white">Edit Student</b></div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form [formGroup]="StudentModel"  (ngSubmit)="updateStudent()">
                                <div class="form-group" hidden>
                                    <label>ID</label>
                                    <input class="form-control" type="text" id="id" formControlName="id"
                                        placeholder="ID" readonly>
                                </div>
                                <div class="row">

                                    <div class="col-md-3">
                                        <label for="studentID">Student ID</label>
                                        <input type="text" class="form-control" name="studentID"  id="studentID" formControlName="studentID" readonly/>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="FirstName"> First Name</label>
                                        <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="LastName"> Last Name</label>
                                        <input type="text" class="form-control"  id="lastName" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="MiddleName"> Middle Name</label>
                                        <input type="text" class="form-control" id="middleName" formControlName="middleName" required />
                                    </div>
                
                                </div>
                
                                <div class="row" style="padding-top:5px;">
                                    <div class="col-md-3">
                                        <label for="Gender">Gender</label>
                                        <select class="form-control" id="Gender"  formControlName="gender"
                                         [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                            <option value="">Select Gender</option>    
                                            <option value="M">Male</option>    
                                            <option value="F">Female</option>    
                                        </select>
                                        
                                        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                            <div *ngIf="f.gender.errors.required">Gender is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="LastName">Date of Birth</label>
                                        <input type="text" class="form-control datepicker" id="DOB"  formControlName="dob"/>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="CurrentTerm">Current Term  </label>
                                        <input type="text" class="form-control"  id="currentTerm"  
                                        formControlName="currentTerm"  [ngClass]="{ 'is-invalid': submitted && f.currentTerm.errors }"/>
                                        <div *ngIf="submitted && f.currentTerm.errors" class="invalid-feedback">
                                            <div *ngIf="f.currentTerm.errors.required">Current Term is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="GradeName">Grade Name</label>
                                        <input type="text" class="form-control" id="gradeName"  formControlName="gradeName" [ngClass]="{ 'is-invalid': submitted && f.gradeName.errors }"/>
                                        <div *ngIf="submitted && f.gradeName.errors" class="invalid-feedback">
                                            <div *ngIf="f.gradeName.errors.required">Grade Name is required</div>
                                        </div>
                                    </div>
                
                                </div>
                
                                <div class="row" style="padding-top:5px;">
                                    <div class="col-md-3">
                                        <label for="SchoolEmailAddress">School Email Address </label>
                                        <input type="text" class="form-control" id="schoolEmailAddress" (change)="validateChangeEmail($event,'school email','school email address')" formControlName="schoolEmailAddress" />                                        
                                    </div>
                                    <div class="col-md-3">
                                        <label for="PersonalEmailAddress"> Personal Email Address </label>
                                        <input type="text" class="form-control"  id="personalEmailAddress" (change)="validateChangeEmail($event,'personal email','personal email address')" formControlName="personalEmailAddress" />
                                    </div>
                                    <div class="col-md-3">
                                        <label for="Contactno"> Contact Number </label>
                                        <input type="text" class="form-control"  id="contactNumber" (keypress)="validateMobile($event)"  formControlName="contactNumber"/>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="CellPhoneNumber">Cell PhoneNumber    </label>
                                        <input type="text" class="form-control" id="cellPhoneNumber" (keypress)="validateMobile($event)" formControlName="cellPhoneNumber"/>
                                    </div>
                                </div>
                
                
                                <div class="row" style="padding-top:5px;">
                                    <div class="col-md-3">
                                        <label for="MaritalStatus">Marital Status   </label>
                                        <select class="form-control select2-selection" id="maritalStatus" formControlName="maritalStatus">
                                            <option value="">Marital Status</option>
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                          
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="DependentStatus">Dependent Status   </label>
                                        <select class="form-control select2-selection" id="dependentStatus" formControlName="dependentStatus">
                                            <option value="">Dependent Status</option>
                                            <option value="D">Dependent</option>
                                            <option value="I">Independent</option>
                                          
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="SocialSecurityNo">Social Security No   </label>
                                        <input type="text" class="form-control" id="socialSecurityNo" formControlName="socialSecurityNo" [ngClass]="{ 'is-invalid': submitted && f.socialSecurityNo.errors }"/>
                                        <div *ngIf="submitted && f.socialSecurityNo.errors" class="invalid-feedback">
                                            <div *ngIf="f.socialSecurityNo.errors.required">SocialSecurityNo is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="Address">Address    </label>
                                        <input type="text" class="form-control" id="address" formControlName="address" required />
                                    </div>
                                </div>
                
                                <div class="row" style="padding-top:5px;">
                                    <div class="col-md-3">
                                        <label for="City">City  </label>
                                        <input type="text" class="form-control" id="city" formControlName="city" required />
                                    </div>
                                    <div class="col-md-3">
                                        <label for="State">State</label>
                                        <select class="form-control select2-selection" id="state" formControlName="state">
                                            <option value="0" selected>--Select State --</option>                                           
                                            <option value="Alabama">Alabama</option>
                                            <option value="Alaska">Alaska</option>
                                            <option value="Arizona">Arizona</option>
                                            <option value="Arkansas">Arkansas</option>
                                            <option value="California">California</option>
                                            <option value="Colorado"> Colorado  </option>
                                            <option value="Connecticut"> Connecticut</option>
                                            <option value="Delaware"> Delaware </option>
                                            <option value="Florida"> Florida</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Idaho">Idaho </option>
                                            <option value="Illinois">Illinois</option>
                                            <option value="Indiana">Indiana</option>
                                            <option value="Iowa">Iowa   </option>
                                            <option value="Kansas">Kansas</option>
                                            <option value="Kentucky">Kentucky</option>
                                            <option value="Louisiana">Louisiana </option>
                                            <option value="Maine">Maine</option>
                                            <option value="Maryland"> Maryland</option>
                                            <option value="Massachusetts">Massachusetts</option>
                                            <option value="Michigan"> Michigan</option>
                                            <option value="Minnesota"> Minnesota </option>
                                            <option value="Mississippi"> Mississippi </option>
                                            <option value="Missouri">Missouri</option>
                                            <option value="Montana">Montana</option>
                                            <option value="Nebraska">Nebraska</option>
                                            <option value="Nevada">Nevada</option>
                                            <option value="New Hampshire"> New Hampshire</option>
                                            <option value="New Jersey"> New Jersey</option>
                                            <option value="New Mexico">New Mexico</option>
                                            <option value="New York">New York</option>
                                            <option value="North Carolina">North Carolina</option>
                                            <option value="North Dakota">North Dakota</option>
                                            <option value="Ohio">Ohio</option>
                                            <option value="Oklahoma">Oklahoma</option>
                                            <option value="Oregon">Oregon</option>
                                            <option value="Pennsylvania"> Pennsylvania</option>
                                            <option value="Rhode Island">Rhode Island </option>
                                            <option value="South Carolina">South Carolina</option>
                                            <option value="South Dakota">South Dakota</option>
                                            <option value="Tennessee">Tennessee</option>
                                            <option value="Texas">Texas</option>
                                            <option value="Utah">Utah</option>
                                            <option value="Vermont">Vermont</option>
                                            <option value="Virginia">Virginia</option>
                                            <option value="Washington">Washington</option>
                                            <option value="West Virginia">West Virginia</option>
                                            <option value="Wisconsin">Wisconsin</option>
                                            <option value="Wyoming">Wyoming</option>                
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="ZipCode">ZipCode   </label>
                                        <input type="text" class="form-control"  id="zipcode" formControlName="zipcode" required />
                                    </div>
                                    <div class="col-md-3">
                                        <label for="TaxYear">TaxYear      </label>
                                        <input type="text" class="form-control" id="taxYear" formControlName="taxYear" required />
                                    </div>
                                </div>
                
                               
                
                
                                <div class="row" style="padding-top:20px;">
                                    <div class="col-md-12 text-left">
                                        <!-- <input type="submit" class="btn btn-primary btn-sm" value="Update" />&nbsp; -->
                                        <button type="submit" class="btn btn-success mr-1">Update</button>&nbsp;                                       
                                       
                                        <a *ngIf="from!='student'" href="admin-studentlist" class="btn btn-primary" value="Back to list">Back to list</a>
                                        <a *ngIf="from=='student'" href="dashboard/student" class="btn btn-danger">Cancel</a>
                                    </div>
                                </div>
                            
                            </form>

                            <!-- AG GRID BINDING-->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->