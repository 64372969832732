<!-- <app-loading></app-loading> -->
<router-outlet></router-outlet>
<!-- <app-admin-master></app-admin-master> -->

<!-- <app-staff-master></app-staff-master> -->

<!-- <app-student-master></app-student-master>  -->

 <!-- <app-admin-login></app-admin-login>  -->

 <!-- <router-outlet></router-outlet> -->

<button style="display: none;" (click)="makeHttpCall()"></button>

<app-my-loader></app-my-loader>