<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" aria-current="page"><span>Create Staff</span></li>
                    </ol>
                </div>
            </div>
            <div class="card">
                <div class="card-header bg-secondary"><b class="text-white">Add Staff</b></div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form [formGroup]="StaffModel" (ngSubmit)="saveStaff()">
                                <div class="form-group" hidden>
                                    <label>ID</label>
                                    <input class="form-control" type="text" id="id" formControlName="id"
                                        placeholder="ID" readonly>
                                </div>

                                <div class="row">
                                    <div class="col-md-6"> 
                                          <div class="form-group">
                                            <label>Employee ID<span class="text-danger">*</span></label>
                                              <input class="form-control" type="text" id="employeeId" formControlName="employeeId"
                                            placeholder="Employee ID" [ngClass]="{ 'is-invalid': submitted && f.employeeId.errors }" required>
                                            <div *ngIf="submitted && f.employeeId.errors" class="invalid-feedback">
                                                <div *ngIf="f.employeeId.errors.required">EmployeeId is required</div>
                                            </div>

                                            </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Password<span class="text-danger">*</span></label>
                                            <input class="form-control" type="password" id="password"
                                             formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                placeholder="Password" required>
                                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                                </div>
                                        </div>
                                     
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-md-6">   <div class="form-group">
                                        <label>First Name<span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" id="firstName" formControlName="firstName"
                                            placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" required>
                                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                            </div>
                                    </div>
                                  
                                </div>
                                    <div class="col-md-6"> <div class="form-group">
                                        <label>Last Name</label>
                                        <input class="form-control" type="text" id="lastName" formControlName="lastName"
                                            placeholder="Last Name">
                                    </div>                              
                                 </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">  
                                          <div class="form-group">
                                        <label>Email Address<span class="text-danger">*</span></label>
                                        <input class="form-control" type="email" id="email"
                                            formControlName="email"  placeholder="EmailAddress" (change)="validateChangeEmail($event)" required >
                                    </div> 
                                  
                                </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Contact No</label>
                                            <input class="form-control" type="text" id="contactno" formControlName="contactno"
                                               (keypress)="validateMobile($event)" placeholder="Contact No">
                                        </div>
                                     </div>
                                    
                                </div>
                             <div class="row">                                 
                                 <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Name Series</label>
                                        <!-- <input class="form-control" type="text" id="nameSeries" formControlName="nameSeries"
                                            placeholder="Name Series" required> -->
                                            <div class="pb-2"> <span class="text-secondary"><strong>Select All :</strong></span><br>
                                                <label class="new-control new-checkbox checkbox-outline-primary">
                                                <input type="checkbox" [checked]="selectall.checked" class="new-control-input"
                                                 (change)="onchangeselectAll($event)" />                                               
                                                <span class="new-control-indicator"></span>A-Z
                                                </label>                                               
                                            </div> 
                                            <span class="text-secondary pb-2"><strong>Select Any :</strong></span> <br>
                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-outline-primary" *ngFor="let data of nameseriesdata; let i=index">
                                                <input type="checkbox" class="new-control-input" [value]="data.value" [checked]="data.checked" (change)="onCheckboxChange($event)"/>
                                                <span class="new-control-indicator"></span>{{data.name}}
                                                </label>                                               
                                            </div>
                                            </div>
                                          
                                        </div>
                                    </div>
                                <div class="form-group" hidden>
                                    <label>Department</label>
                                    <input class="form-control" type="text" id="department" formControlName="department"
                                        placeholder="Department" value="StudentAid">
                                </div>

                                <div class="form-group" hidden>
                                    <label>School</label>
                                    <input class="form-control" type="text" id="school" formControlName="school"
                                        placeholder="School" value="ALASU">
                                </div>


                                <div class="form-group">
                                    <button type="submit" class="btn btn-success mr-1">Submit</button>&nbsp;
                                    <!-- <button class="btn btn-danger" (click)="onCancel()">Cancel</button>&nbsp; -->
                                    <a href="manage-staff" class="btn btn-primary">Cancel</a>
                                </div>
                            </form>

                            <!-- AG GRID BINDING-->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->