import { Component, OnInit } from '@angular/core';
import { FormsModule,FormControl,FormGroup, Form,FormBuilder,Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoginService } from '../../shared/login/login.service';
import { StaffService } from '../../shared/staff/staff.service'; 
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-upload-student',
  templateUrl: './upload-student.component.html',
  styleUrls: ['./upload-student.component.css']
})
export class UploadStudentComponent implements OnInit {

  public studentForm:FormGroup;
  public id:string;
  public user:any;
  public link:any;
  constructor(private titleservice:Title,private fb:FormBuilder,private loginService:LoginService,private staffservice:StaffService) {
    this.titleservice.setTitle("Upload Student");
    this.studentForm = fb.group({
      student:['',Validators.required]
    })
   }

  ngOnInit(): void {
     this.id = this.loginService.getToken();
      this.user = this.loginService.getUser();
    if(this.user!=null||this.user!=undefined){
      if(this.user.role=="staff"||this.user.role=="admin"){
        if(this.user.role=="staff"){
          this.id= this.user.employeeId;
          this.link=true;
        }else{
          this.link=false;
        }
      }else{
        window.location.href="admin";
      }
    }else{
      window.location.href="admin";
    }
  }

  save(){
    if(this.studentForm.value.student=="")
    {
      Swal.fire("Warning","Please enter student data.","warning");
      return;
    }
    let studentdata = this.studentForm.value.student;
    studentdata = studentdata.toString().replace(/\n/g,'^');   
    let dt = studentdata.toString().replace(/\t/g,'~');
    
    let data = {
        studentdata:dt,
        employeeid:this.id
      }
  debugger;
      this.staffservice.uploadstudent(data).subscribe(res=>{
        //console.log(res);
        if(res.status == 1){
         // Swal.fire("Success!","Students saved successfully","success");
         Swal.fire("Warning!",res.message,"warning");
        }else{
         // Swal.fire("Warning!","Error in uploading student","warning");
         Swal.fire("Warning!",res.message,"warning");
        }

      });

  }

}
