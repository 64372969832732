import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../shared/login/login.service';
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {

  constructor(private loginservice:LoginService) { }

  ngOnInit(): void {

  }

  logout()
  {
      this.loginservice.logout();

      window.location.href="admin";
  }
}
