<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
         <div class="row">
           <div class="col-md-12">           
              <ol class="breadcrumb">
                  <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                  <li class="breadcrumb-item active text-primary" aria-current="page"><span>Manage Staff</span></li>
              </ol>
        
           </div>
         </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Staff List</b></span>
                            </div>
                            <div class="card-body">
                              <div class="row">                   
                                <div class="col-md-12 text-right">
                                  <a href="create-staff" class="btn btn-primary"><i class="fa fa-plus"></i>&nbsp; Add Staff</a>&nbsp;                                 
                                 
                                </div>
                              </div>
                              
                              <div class="table-responsive" style="padding-top:15px">
                                <table id="tblStaff" class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <th>Name</th>                                       
                                        <th>Contact No</th>
                                        <th>Email</th>
                                        <th>Name Series</th>
                                        <th>Department</th>
                                        <th>School</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr *ngFor="let item of rowDataStaff">
                                        <td>{{item.firstName}} {{item.lastName}}</td>                                       
                                        <td>{{item.contactno}}</td>
                                        <td>{{item.emailAddress}}</td>
                                        <td>{{item.nameSeries}}</td>
                                        <td>{{item.department}}</td>
                                        <td>{{item.school}}</td>
                                        <td>
                                          <a href="create-staff?id={{item.id}}" class="btn btn-success fa fa-edit" title="Edit"></a>&nbsp;
                                          <button (click)="Delete(item.id)" class="btn btn-danger fa fa-trash" title="Delete"></button>
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            </div>
                          </div>
                        </div>
                    </div>  
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>
</div>

<!-- END MAIN CONTAINER -->