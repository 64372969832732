import { Component, OnInit } from '@angular/core';
import { StaffService } from '../../shared/staff/staff.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/login/login.service';
import {Title} from "@angular/platform-browser";
declare var $;
@Component({
  selector: 'app-manage-staff',
  templateUrl: './manage-staff.component.html',
  styleUrls: ['./manage-staff.component.css']
})
export class ManageStaffComponent implements OnInit {

  public disable=false;
  public submitted = false;
  public flag = false;
  public staffData:any[]=[];
  public rowDataStaff:any[]=[];
  public user: any;
  public dob: string = "";
  public disableExportBtn=true;
 

  constructor(private titleservice:Title,private staffservice:StaffService,private loginservice:LoginService,private route:Router) { 
    this.titleservice.setTitle("Manage Staff");
    this.getStaff();
    setTimeout(function(){
     $(function(){
        $("#tblStaff").DataTable({
          dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
        
          buttons: {          
              buttons: [              
                  { extend: 'csv', className: 'btn' },
                  { extend: 'excel', className: 'btn' },
                  { extend: 'print', className: 'btn' }
              ]
          },
         
          "oLanguage": {
            "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
            "sInfo": "Showing page _PAGE_ of _PAGES_",
            "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            "sSearchPlaceholder": "Search...",
           "sLengthMenu": "Results :  _MENU_",
        },
        "order": [[ 3, "desc" ]],
        "stripeClasses": [],
        "lengthMenu": [5, 10, 20, 50],
        "pageLength": 5,
        drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
        });
     });

    },3000);
  }

  ngOnInit(): void {

    let user=this.loginservice.getUser();
    if(user!=null||user!=undefined){
      if(user.role!="admin"){
        this.route.navigate(['admin']);
      }
    }else{
      this.route.navigate(['admin']);
    }
   
  }

  getStaff() {
    this.staffservice.getStaff().subscribe(
      staff => {
        console.log(staff);
        this.staffData = staff.data;
        
        this.rowDataStaff = [];
        if(this.staffData.length>0){

         this.disableExportBtn=false;

          for(let i=0;i<this.staffData.length;i++){

            let obj = {
              id: this.staffData[i].id,
              firstName: this.staffData[i].firstName,
              lastName: this.staffData[i].lastName,
              contactno: this.staffData[i].contactno,
              emailAddress: this.staffData[i].emailAddress,
              nameSeries: this.staffData[i].nameSeries,
              employeeId: this.staffData[i].employeeId,
              department: this.staffData[i].department,
              school: this.staffData[i].school,
            }
              this.rowDataStaff.push(obj);
          }

        }
        this.flag = true;
      });

      
  }


  Delete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
            this.staffservice.DeleteStaff(id).subscribe(res=>{
              if(res.status==1){
                this.getStaff();
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Staff deleted successfully'
                });
              }else{
                Swal.fire({
                  icon: 'warning',
                  title: 'Warning',
                  text: 'Error in deleting staff'
                });
              }
            });   
      }
      
    });
  }

}
