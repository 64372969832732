import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../shared/login/login.service';

@Component({
  selector: 'app-student-sidebar',
  templateUrl: './student-sidebar.component.html',
  styleUrls: ['./student-sidebar.component.css']
})
export class StudentSidebarComponent implements OnInit {

  public user:any;
  public studentid:string;
  constructor(private loginservice:LoginService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUser();
    this.studentid = this.user.id;
  }

  logout()
  {
      this.loginservice.logout();

      window.location.href="student";
  }

}
