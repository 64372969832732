import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../shared/login/login.service';
@Component({
  selector: 'app-staff-sidebar',
  templateUrl: './staff-sidebar.component.html',
  styleUrls: ['./staff-sidebar.component.css']
})
export class StaffSidebarComponent implements OnInit {

  constructor(private loginservice:LoginService) { }

  ngOnInit(): void {
  }

  logout()
  {debugger;
    this.loginservice.logout();
    window.location.href="staff";
  }

}
